import {
  Box,
  Button,
  ColorModeProvider,
  Column,
  Columns,
  ContentBlock,
  ExternalLink,
  Section,
  Stack,
} from "@dangerfarms/ui-system";
import {
  ApproachCard,
  Page,
  StructuredData,
  CaseStudyGrid,
} from "@dangerfarms/website-core";
import css from "@styled-system/css";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { PrismicText } from "@dangerfarms/prismic";
import styled from "styled-components";
import trackEvent from "@dangerfarms/analytics/eventTracking";

const HeroSection = styled(Section)`
  min-height: calc(100vh - ${({ theme }) => theme.sizes.headerHeight}px);
`;

const HeroText = styled(Column)`
  @media screen and (min-width: ${({ theme }) => theme.contentWidth[0]}) {
    padding-top: 100px;
  }
`;

const HeroHeading = styled(PrismicText)`
  & > span {
    display: block;
  }
`;

const HeroImage = styled(Column)`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.contentWidth[0]}) {
    display: block;
  }
`;

const FullHeightSection = styled(Section)`
  min-height: 100vh;
`;

const MarketingCampaignSection = styled(Box)`
  box-shadow: inset 0 3px 9px 0 rgba(0, 0, 0, 0.25);

  ${css({
    backgroundColor: "bg",
    paddingX: "gutter",
    paddingY: 6,
  })};
`;

// There's not much gain to be had from maintaining prop type definitions for
// this component, because they're passed from Gatsby, it's not an interface
// we'll ever need to use.
//
/* eslint-disable react/prop-types */
const IndexPage = ({ data }) => {
  const { baseUrl, description, logo, title } = data.site.siteMetadata;

  // Required check for no data being returned
  const prismicHomepage = data.prismic.allHomepages.edges.slice(0, 1).pop();
  if (!prismicHomepage) {
    return null;
  }
  const prismic = prismicHomepage.node;
  const caseStudies = prismic.case_study_showcase.map(item => item.case_study);
  const slices = prismic.body;

  return (
    <Page description={description}>
      <StructuredData
        data={{
          "@context": "http://schema.org",
          "@type": "WebSite",
          name: title,
          description,
          url: baseUrl,
          mainEntityOfPage: baseUrl,
          image: logo,
          author: {
            "@type": "Organization",
            name: "Danger Farms",
          },
        }}
      />

      <HeroSection backgroundColor="bg">
        <Columns>
          <HeroText flex={1.5}>
            <Stack>
              <HeroHeading
                maxWidth={`${prismic.hero_heading_width}em`}
                richText={prismic.hero_heading}
                variant="insane"
                withHighlights
              />
              <PrismicText
                maxWidth={`${prismic.hero_lede_width}em`}
                richText={prismic.hero_lede}
                variant="lede"
                withHighlights
              />
            </Stack>
          </HeroText>
          <HeroImage flex={1}>
            <Img
              alt={prismic.hero_image.alt}
              fluid={prismic.hero_imageSharp.childImageSharp.fluid}
              title={prismic.hero_image.alt}
            />
          </HeroImage>
        </Columns>
      </HeroSection>

      <FullHeightSection backgroundColor="barelyGrey">
        <Stack>
          <PrismicText
            maxWidth={`${prismic.about_heading_width}em`}
            richText={prismic.about_heading}
            variant="epic"
            withHighlights
          />
          <PrismicText
            maxWidth={`${prismic.about_lede_width}em`}
            richText={prismic.about_lede}
            variant="lede"
            withHighlights
          />
          <Button as={Link} to="/case-studies">
            {prismic.case_studies_cta}
          </Button>
        </Stack>
      </FullHeightSection>

      {slices.map((slice, i) => {
        switch (slice.__typename) {
          case "PRISMIC_HomepageBodyMarketing_campaign_banner": {
            return (
              // There's not really a better choice of key here unfortunately
              // eslint-disable-next-line react/no-array-index-key
              <ColorModeProvider key={i} mode="accent">
                <MarketingCampaignSection>
                  <ContentBlock>
                    <Box maxWidth="38em">
                      <Stack>
                        <PrismicText
                          richText={slice.primary.heading}
                          variant="heading"
                        />
                        <PrismicText
                          richText={slice.primary.description}
                          weight="news"
                        />
                        <Button
                          as={ExternalLink}
                          href={slice.primary.link.url}
                          onClick={() =>
                            trackEvent(slice.primary.campaign_name, "Click")
                          }
                        >
                          {slice.primary.cta}
                        </Button>
                      </Stack>
                    </Box>
                  </ContentBlock>
                </MarketingCampaignSection>
              </ColorModeProvider>
            );
          }
          case "PRISMIC_HomepageBodyOur_approach": {
            return (
              // There's not really a better choice of key here unfortunately
              // eslint-disable-next-line react/no-array-index-key
              <FullHeightSection key={i} backgroundColor="bg">
                <Stack boxWidth="100%" space={2}>
                  <Box
                    alignItems="center"
                    boxDisplay={["block", "flex"]}
                    boxWidth="100%"
                  >
                    <PrismicText
                      maxWidth={`${slice.primary.title_width}em`}
                      minWidth={[
                        "100%",
                        `calc(${slice.primary.title_width}em + 20px)`,
                      ]}
                      richText={slice.primary.title}
                      variant="epic"
                      withHighlights
                    />
                    <Box flex={1} />
                    <Box boxDisplay={["none", "block"]}>
                      <Button as={Link} to="/approach">
                        {slice.primary.approach_cta}
                      </Button>
                    </Box>
                  </Box>
                  <PrismicText
                    maxWidth={`${slice.primary.lede_width}em`}
                    richText={slice.primary.lede}
                    variant="lede"
                    withHighlights
                  />
                  <Columns justify="space-between" wrap="wrap">
                    {slice.fields.map(card => (
                      <ApproachCard
                        key={card.card_title[0].text}
                        text={card.card_text}
                        title={card.card_title}
                      />
                    ))}
                  </Columns>
                </Stack>
              </FullHeightSection>
            );
          }
          default: {
            return null;
          }
        }
      })}

      <Section backgroundColor="paleGrey">
        <Stack align="stretch" space={3}>
          <Box alignItems="center" boxDisplay={["block", "flex"]}>
            <PrismicText
              as="h2"
              richText={prismic.latest_work_heading}
              variant="heading"
            />
            <Box flex={1} />
            <Box boxDisplay={["none", "block"]}>
              <Button as={Link} to="/case-studies">
                {prismic.all_case_studies_cta}
              </Button>
            </Box>
          </Box>
          <CaseStudyGrid caseStudies={caseStudies} />
        </Stack>
      </Section>
    </Page>
  );
};
/* eslint-enable */

export const query = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        baseUrl
        description
        logo
        title
      }
    }

    prismic {
      allHomepages {
        edges {
          node {
            hero_heading
            hero_heading_width
            hero_lede
            hero_lede_width
            hero_image
            hero_imageSharp {
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            about_heading
            about_heading_width
            about_lede
            about_lede_width
            case_studies_cta
            all_case_studies_cta
            body {
              ... on PRISMIC_HomepageBodyMarketing_campaign_banner {
                type
                primary {
                  campaign_name
                  cta
                  description
                  heading
                  link {
                    _linkType
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                    ... on PRISMIC__Document {
                      _meta {
                        uid
                      }
                    }
                  }
                }
                label
              }
              ... on PRISMIC_HomepageBodyOur_approach {
                type
                label
                fields {
                  card_text
                  card_title
                }
                primary {
                  approach_cta
                  lede
                  lede_width
                  title
                  title_width
                }
              }
            }
            latest_work_heading
            case_study_showcase {
              case_study {
                ... on PRISMIC_Case_study {
                  ...CaseStudy
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
